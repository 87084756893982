<template>
  <v-app>
    <v-sheet class="mt-5">
      <v-progress-linear
          indeterminate
          class="mx-auto text-center"
          color="blue darken-4"
          v-show="load"
        />
      <v-row>
        <v-col>
          <v-container>
            <v-sheet rounded="" elevation="5" dark color="indigo darken-4">
              <v-row>
                <v-col>
                  <div
                    v-text="'Detalles'"
                    class="headline font-weight-light text-center"
                  ></div>
                </v-col>
              </v-row>
            </v-sheet>
          </v-container>
        </v-col>
      </v-row>
      <v-container fluid class="mx-5">
        <v-row>
          <v-col>
            <div v-text="'Remitente'" class="title font-weight-medium"></div>
            <div
              v-text="parametrosRuta.data.nombre_remitente"
              class="body font-weight-light ml-3"
            ></div>
          </v-col>
          <v-col>
            <div v-text="'Documento'" class="title font-weight-medium"></div>
            <div
              v-text="parametrosRuta.data.nombre_documento"
              class="body font-weight-light ml-3"
            ></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div v-text="'Comentarios'" class="title font-weight-medium"></div>
            <div
              v-text="parametrosRuta.data.comentario"
              class="body font-weight-light ml-3"
            ></div>
          </v-col>
          <v-col>
            <v-btn :disabled="load==true" v-if="parametrosRuta.data.reasignado==false" text color="indigo darken-4" @click="revisar_archivos_caso"
              >REASIGNAR</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      parametrosRuta: {},
      load: false
    };
  },
  created() {
    console.log(this.$route.params);
    this.parametrosRuta = this.$route.params.data
  },
  methods: {
   async revisar_archivos_caso() {
     this.load = true
     console.log(this.parametrosRuta)
     console.log(this.parametrosRuta.data.objectId_remitente)
      
      this.$store.state.mostrarCasos = false
      /*
        var f = new Date();
        var fecha = f.getFullYear() + "-" + (("0" + (f.getMonth() + 1)).slice(-2)) + "-" + ("0" + f.getDate()).slice(-2)
       console.log(fecha) 
       */
       console.log('das')
        try {
          let response = await this.$store.dispatch('llamado_get',
          {url: 'https://siett.educandote.co/parse/classes/prueba01casos/'+this.parametrosRuta.data.objectId_remitente,
          tipo_header:'parse'})
          console.log(response.data)
          
            let obj = {}
            obj.remitente = this.parametrosRuta.data.nombre_remitente
            obj.identificacion_remitente = this.parametrosRuta.data.identificacion_remitente //Lo puedo borrar
            obj.userId_remitente = this.parametrosRuta.data.userId_remitente
            obj.correo_remitente = this.parametrosRuta.data.correo_remitente
            obj.area_remitente = this.parametrosRuta.data.sector_remitente
            
            obj.objectId_remitente = this.parametrosRuta.data.objectId
            obj.fecha_aceptado = Date.parse(new Date()) // ACTUAL
            //-------------------------------------------------------
            
            
            obj.fecha_rechazado = null, //Cambiara de null a la fecha si el caso es rechazado por un supervisor
            obj.fecha_respondido = null, //Cambiara de null a la fecha cuando el caso es respondido por un supervisor
            //---------------------------------------                
            obj.fecha_asignacion_caso = this.parametrosRuta.data.fecha // Fecha del PRUEBA01SUPERVISOR

            obj.tipo_documental = response.data.tipo_documental
            obj.nombre_documento = response.data.nombre_documento //Nombre del documento con el cual se recibira en la bandeja de entrada
            obj.meta_data = response.data.meta_data //Son los inputs del tipo documental seleccionado 
            obj.token = response.data.token //Token del caso
            obj.estado = 'Reasignado' // Este estado indicara "si esta en progreso, o ya fue respondido, rechazado, aceptado"

            obj.nombre_supervisor = this.$store.state.dataUser.dataUser.nombre //Campo que se llenara una vez algun supervisor haya aceptado el caso
            obj.identificacion_supervisor = this.$store.state.dataUser.dataUser.identificacion //se llenera cuando algun supervisor acepte
            obj.correo_supervisor = this.$store.state.dataUser.dataUser.correo //Se llenara una vez algun supervisor acepte
            obj.userId_supervisor = this.$store.state.dataUser.dataUser.userId

            obj.sector = this.$store.state.dataUser.dataUser.nombre_sector
            obj.area_id = this.$store.state.dataUser.dataUser.sector


            obj.responsables = response.data.responsables // los responsables del area al que ingresa sera un array de objetos de la forma: {nombre: 'fry', identificacion: 1004 } este array se llenara en la vista del supervisor
            obj.workflow = response.data.workflow  //workflow asignado por el recepcionista que indica las areas por las que pasa el documento

            obj.fecha_salida = null // fecha en la que se culmina su proceso dentro del area y es enviado a otra area o al usuario final
           
            obj.correo_de_contacto = response.data.correo_de_contacto
            obj.informacion_area = response.data.informacion_area
            obj.carpeta_publica = response.data.carpeta_publica
            
            obj.id_nodo_sector_anterior_numeric = response.data.id_nodo_sector
            obj.id_nodo_sector = response.data.id_nodo_sector_anterior_numeric
            console.log(obj)

         
            let consulta  = await this.$store.dispatch('llamado_post',
            {url: 'https://siett.educandote.co/parse/classes/prueba01casos/',
            body: obj,
           
            tipo_header:'parse'})

            console.log(consulta)
           let consulta2 = await this.$store.dispatch('llamado_get',
            {url: 'https://siett.educandote.co/parse/classes/prueba01casos/'+consulta.data.objectId,
            tipo_header:'parse'})
            console.log(consulta2.data)


  
            response = await this.$store.dispatch('llamado_put',
            {url: 'https://siett.educandote.co/parse/classes/prueba01RespuestasSupervisores/'+this.parametrosRuta.data.objectId,
            body: {
              "reasignado": true
            },
           
            tipo_header:'parse'})
            console.log(response)

             

            let id = {
              id: consulta2.data.token,
              data: consulta2
            };
          
            id = JSON.stringify(id); //Convierte a string el objeto de arriba
            console.log(id);
            id = btoa(id); //Convierte a base 64 el struing de arriba
            console.log(id); //Asegurate que sea unico

            this.$router.push({
              name: "informacion_caso_supervisor",
              params: {id: id},
            });
            this.$store.state.mostrarDatos = true    
        } catch (error) {
          console.log(error)
          this.$store.commit('error','Ha ocurrido un error inesperado reasignando el caso '+error.message)
        }
        this.load=false
        

        
        //console.log(this.$store.state.documentos.documentos);
        //this.$store.state.mostrarDatos = true;
      
    },
  },
};
</script>